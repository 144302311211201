
.header {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: $header-height;
    background-color: $color-primary-light;
    color: $color-on-primary;

    @include md {
      height: 72px;
    }

    .content {
      width: 100%;
      margin: 0 10px;

      @include md {
        margin: 0 50px;
      }
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.include-back {
        justify-content: space-between;

        .back {
          display: flex;
        }
      }
    }

    .back {
      align-items: center;
      display: none;
      font-size: 15px;

      .back-arrow {
        width: 18px;
        height: auto;
        margin-right: 10px;
      }
    }

    .logo {
      height: 32px;
      @include md {
         height: 45px;
      }
    }
}