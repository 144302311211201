// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin sm-hidden {
  @media (max-width: #{$screen-sm-min - 1}) {
    display: none;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin md-hidden {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min - 1}) {
    display: none;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin lg-hidden {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-min - 1}) {
    display: none;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xl-hidden {
  @media (min-width: #{$screen-lg-min}) {
    display: none;
  }
}

.sm-hidden {
  @include sm-hidden();
}

.md-hidden {
  @include md-hidden();
}

.lg-hidden {
  @include lg-hidden();
}

.xl-hidden {
  @include xl-hidden();
}

html,
body {
  height: 100%;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  color: $color-background-primaryTint;
  font-size: $font-size;

  @include md {
    font-size: $md-font-size;
  }

  line-height: $line-height;
  font-family: $font-family;

  margin: 0;

  .page-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main-content {
      flex: 1;
      width: 100%;

      iframe {
        overflow: auto;
        height: 100%;
        width: 100%;
        border: none;
      }
    }
  }
}


